import React from "react";
import "./CSS/Prdts.css";
import "./CSS/About.css";
import Helmet from "react-helmet";
import piperesult from "./Images/resultpipe.jpg";
import loginpage from "./Images/loginpage.jpg";
import testing from "./Images/testing.JPG";
import ROV from "./Images/ROV.png";
import Table from "react-bootstrap/Table";
import Carousel from "react-bootstrap/Carousel";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import Zoom from "react-reveal/Zoom";
import Image from "react-bootstrap/Image";

function Prdts() {
  return (
    <>
      <Helmet>
        <title>
          IXAR ROBOTIC SOLUTIONS | SONAR SERVICES | LESER SCALING | Thickness
          Measurements (UT & PECT) | PRODUCT{" "}
        </title>
        <meta
          name="description"
          content="ROV is a portable, stable, adventurous underwater drone that can conquer the waters and is easily operated through Intuitive flight controls."
        />
        <meta
          name="Keywords"
          content="best underwater rov inspections services, rov services, underwater inspection, image enhancement,user interface,
        tunnel inspection,tiver-bed profiling/bathymetry,pre-repair inspection,dam construction support,upstream/downstream/tail race inspection"
        />
      </Helmet>
      <div className="hero-containerp">
        <h1>ROV 1.0</h1>
      </div>

      <div className="container-fluid">
        <div className="container">
          <h2
            style={{
              padding: "3%",
              fontSize: "38px",
              color: "rgb(70, 145, 202)",
            }}
          >
            <strong>Product Details</strong>

            <Zoom>
              <hr
                style={{
                  width: "60%",
                  marginLeft: "20%",
                  border: "1px solid #4691ca",
                }}
              />
            </Zoom>
          </h2>
        </div>
      </div>

      <div className="container-fluid mb-5 px-4">
        <div className="row">
          <Fade left>
            <div className="col-md-6" style={{ padding: "0% 5% 0% 5%" }}>
              <h1 style={{ fontSize: "30px" }}>
                {" "}
                <strong2>Description</strong2>{" "}
              </h1>
              <p
                style={{
                  fontSize: "1.2rem",
                  paddingTop: "3%",
                  textAlign: "justify",
                }}
              >
                ROV is a portable, stable, adventurous underwater drone that can
                conquer the waters and is easily operated through Intuitive
                flight controls. With Eight-Thruster configurations, number of
                available accessories, compatible software which adds to its
                High level of Performance, Flexibility, and Expandability.
                Making it One of the optimised underwater sensing solutions for
                assessing and monitoring underwater systems in the market!
              </p>

              <h1 style={{ paddingTop: "4%", fontSize: "30px" }}>
                {" "}
                <strong2> Modular Lightweight Frame</strong2>{" "}
              </h1>
              <p
                style={{
                  fontSize: "1.2rem",
                  paddingTop: "3%",
                  textAlign: "justify",
                }}
              >
                The ROV chassis is lightweight and sturdy constructed by using
                HDPE material. Simple design with open frame construction and
                generous payload offers the possibility of adding a wide range
                of tools and sensors as well as interchangeable tool skids,
                adding to it versatility and expandability, for different
                applications.
              </p>
            </div>
          </Fade>
          <Fade right>
            <div className="col-sm-6" style={{ padding: "2% 2% 5% 2%" }}>
              <Image src={ROV} style={{ width: "100%", height: "100%" }} />
              {/* <img src={ROV} class="d-block w-100" alt="ROV" /> */}
            </div>
          </Fade>
        </div>
      </div>

      <Fade big>
        <div className="container">
          <div className="row mb-5" style={{ padding: "0% 5% 0% 5%" }}>
            <Table striped bordered hover>
              <thead>
                <tr className=" table-dark">
                  <th>Parameter</th>
                  <th>Specifications</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Dimensions</td>
                  <td>Lenght- 650mm, Width- 480mm, Height- 380mm</td>
                </tr>
                <tr>
                  <td>Weight In Air</td>
                  <td>22kgs</td>
                </tr>
                <tr>
                  <td>Power Input</td>
                  <td> 230V, 4.5A ,@50-60Hz (Single Phase)</td>
                </tr>
                <tr></tr>
                <tr>
                  <td>Depth Rating</td>
                  <td> 200m</td>
                </tr>
                <tr>
                  <td>Operating Temperature Range</td>
                  <td> 15-45 Deg.c</td>
                </tr>
                <tr>
                  <td>Deployment and Recovery</td>
                  <td> Manual + Crane</td>
                </tr>
                <tr>
                  <td>Controller Pad</td>
                  <td> 6 Axis </td>
                </tr>
                <tr>
                  <td>Electrical Safety & Distribution</td>
                  <td>
                    {" "}
                    Short-Circuit Protection, Ground-Fault: Current-Leak
                    Protection, Emergency Shut Down: Over Current Protection
                  </td>
                </tr>
                <tr>
                  <td>Control System</td>
                  <td> Openloop System with Human Machine Inerface</td>
                </tr>
                <tr>
                  <td>Depth Accurancy</td>
                  <td>10mm Resolution</td>
                </tr>
                <tr>
                  <td>Payloads</td>
                  <td> Laser Scaler, 360 Sonar Profiling</td>
                </tr>
                <tr>
                  <td>Illumination </td>
                  <td> 6000 Lumen Forward</td>
                </tr>
                <tr>
                  <td>Vision Feed</td>
                  <td> 1920*1080p @30 FPS</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </Fade>

      <div className="aboutcss mx-4">
        <div className="container mb-5 ">
          <div className="row">
            <div className="col-10 mx-auto"></div>

            <div
              className="container"
              style={{ padding: "1%", textAlign: "justify" }}
            >
              <div className="row">
                <h2
                  style={{
                    padding: "1%",
                    fontSize: "41px",
                    color: "rgb(70, 145, 202)",
                  }}
                >
                  <strong>Software</strong>
                </h2>
                <Zoom>
                  <hr
                    style={{
                      width: "45%",
                      marginLeft: "28%",
                      border: "1px solid #4691ca",
                    }}
                  />
                </Zoom>

                <Fade left>
                  <div className="col-md-6">
                    <p style={{ fontSize: "1.2rem", paddingTop: "3%" }}>
                      <h1 style={{ fontSize: "30px" }}>
                        {" "}
                        <strong2> User Interface</strong2>{" "}
                      </h1>
                      Our software brings a vast number of features, and are
                      exclusively designed for end-users. At the surface, the
                      pilot controls the ROV through a laptop and a controller.
                      The Ground station acts as the user interface. Providing
                      the live video stream, sensor feedback, information, the
                      ability to change settings and configuration.
                    </p>

                    <h1 style={{ fontSize: "30px" }}>
                      {" "}
                      <strong2>Reporting Software</strong2>
                    </h1>
                    <p style={{ fontSize: "1.2rem" }}>
                      Our online reporting solution helps the user to visualize
                      and analyse the inspected parts for its flaws. All the
                      information data is stored online and can be accessed by
                      the user remotely.{" "}
                    </p>

                    <h1 style={{ fontSize: "30px" }}>
                      {" "}
                      <strong2> Image Enhancement(AI)</strong2>{" "}
                    </h1>
                    <p style={{ fontSize: "1.2rem" }}>
                      Artificial Intellegence enables maneuverability during
                      Turbid (Low Visibility) Conditions.{" "}
                    </p>
                  </div>
                </Fade>

                <Fade right>
                  <div className="col-md-6" style={{ paddingTop: "5%" }}>
                    <div className="container-fluid mb-3">
                      <Carousel>
                        <Carousel.Item>
                          <img
                            className="d-block w-100"
                            src={testing}
                            alt="Testing"
                          />
                        </Carousel.Item>
                        <Carousel.Item>
                          <img
                            className="d-block w-100"
                            src={loginpage}
                            alt="LoginPage"
                          />
                        </Carousel.Item>
                        <Carousel.Item>
                          <img
                            className="d-block w-100"
                            src={piperesult}
                            alt="PipeResult"
                          />
                        </Carousel.Item>
                      </Carousel>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Prdts;
