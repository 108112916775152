import React from "react";
import "./App.css";
import { Switch, Route, Redirect } from "react-router-dom";
import { HashRouter } from "react-router-dom";
import Helmet from "react-helmet";
import Contact from "./Components/Contact.jsx";
import Team from "./Components/TeamM.jsx";
import Servc from "./Components/Servc.jsx";
import Product from "./Components/Prdts.jsx";
import Rovpro from "./Components/Rovpro.jsx";
import Footer from "./Components/Footer.jsx";
import Home from "./Components/Home.jsx";
import Patner from "./Components/Patner.jsx";
import Nav from "./Components/NavBar.jsx";
import ScrollToTop from "./Components/Pages/ScrollToTop.jsx";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/js/dist/dropdown";

function App() {
  return (
    <>
      <Helmet>
        <title>IXAR ROBOTIC</title>
        <meta name="description" content="" />
        <meta name="Keywords" content="Best ROV " />
      </Helmet>

      <HashRouter>
        <ScrollToTop />
        <Nav />
        <div className="main"></div>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/home" exact component={Home} />
          <Route path="/services" component={Servc} />
          <Route path="/product" exact component={Product} />
          <Route path="/Rovpro" exact component={Rovpro} />
          <Route path="/team" exact component={Team} />
          <Route path="/contact" exact component={Contact} />
          <Route path="/Partner" exact component={Patner} />
          <Route path="/footer" exact component={Footer} />
          <Redirect to="/home" />
        </Switch>
      </HashRouter>
      <Footer />
    </>
  );
}

export default App;
