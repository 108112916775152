import React from "react";
import "../App.css";
import "./CSS/About.css";
import RROV from "./Images/RROV1.jpg";
import ROVED from "./Images/ROV1.jpeg";
import ROVdetails from "./Images/Rov22.jpg";
import Helmet from "react-helmet";
import po from "./Images/port3.jpg";
import pw from "./Images/pwr2.jpg";
import dm from "./Images/dam6.jpg";
import ol from "./Images/off3.jpg";
import br from "./Images/bridge2.jpg";
import sh from "./Images/shi2.jpg";
import vi from "./Images/Inspection.jpg";
import ls from "./Images/laser.webp";
import tm from "./Images/ut.webp";
import sn from "./Images/radar.jpg";
import rd from "./Images/RD.jpg";
import bth from "./Images/bath.JPG";

import video from "./Images/video2.mp4";

import Carousel from "react-bootstrap/Carousel";

import cover from "./Images/cover.jpeg";
import contactrov from "./Images/contactrov.webp";
import iit from "./Images/iit.png";
import mitg from "./Images/mitg.jpeg";
import sine from "./Images/sine.jpeg";
import tih from "./Images/TIH.jpg";
import india from "./Images/india.png";
import msme from "./Images/msme.jpg";

import Togglesp from "./Pages/Tooglep.jsx";
import Toggleb from "./Pages/Toogleb.jsx";
import Toggled from "./Pages/Toggled.jsx";
import Toggleo from "./Pages/Toggleo.jsx";
import Togglepp from "./Pages/Togglepp.jsx";
import Toggles from "./Pages/Toggles.jsx";

import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import Zoom from "react-reveal/Zoom";
import { CardDeck, Col, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import Image from "react-bootstrap/Image";


function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionToggle(eventKey);

  return (
    <button
      type="button"
      style={{ backgroundColor: "red", fontSize: "1.2em", color: "white" }}
      onClick={decoratedOnClick}
      className="py-2 px-3"
    >
      {children}
    </button>
  );
}

function Home({ children, eventKey }) {
  return (
    <>
      <Helmet>
        <title>
          IXAR ROBOTIC SOLUTIONS | Underwater Inspection Services | ROV
          Inspections |
        </title>

        <meta
          name="description"
          content="IXAR Robotic Solutions covers a vast array of Non-Destructive Testing. 
        We are a one stop solution for all underwater water survey and inspections."
        />
        <meta
          name="Keywords"
          content="best rov services company, best rov company in india, best underwater rov services,bathymetry, "
        />
      </Helmet>

      {/* <img src={cover} class="image-fluid" alt="cover.jpg" width="100%" /> */}
      <video src={video} width="100%"  autoPlay muted loop>
      
      </video>
      {/* <Container fluid>
        <Row>
          <Col sm={12}>
            <Image src={cover} thumbnail style={{height:"100vh",width:"100vw"}} />
          </Col>
        </Row>
      </Container> */}
      {/* <Image
        src={cover}
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
      /> */}
      {/* <div className="hero-container1">
      </div> */}
      {/* <div className="hero-container11">
      </div> */}
      {/* Body Part Start */}
      <div className="animated">
        <h2>
          <span> I</span>XAR<span> R</span>obotic<span> S</span>olutions!
        </h2>
      </div>
      <div className="aboutcss">
        <div class="d-flex">
          <div className="row ">
            <div className="col-10 "></div>
            <div>
              <div class="row border rounded overflow-hidden">
                <div class="col-sm-7" style={{ padding: "2%" }}>
                  <Fade left>
                    {" "}
                    <p
                      style={{
                        fontSize: "1.2rem",
                        paddingTop: "3%",
                        textAlign: "justify",
                      }}
                      class="featurette-heading"
                    >
                      <h1>
                        <strong>Vision</strong>{" "}
                      </h1>
                      <p style={{ textAlign: "center" }}>
                        To Provide high-quality, Made in India underwater drone/
                        Remotely Operated Vehicles for inspection and survey of
                        submersible structure.
                      </p>

                      <h1>
                        <strong>Mission</strong>{" "}
                      </h1>
                      <p style={{ textAlign:"center" }}>
                        It’s Just the beginning of a new dive, however the
                        waters we plan to explore is Deep.
                      </p>
                    </p>
                    <p>
                      <h1>
                        <strong>Who we are</strong>{" "}
                      </h1>
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "1.2rem",
                          padding: "2%",
                        }}
                      >
                        IXAR Robotic Solutions is a one stop solution for all
                        underwater survey and inspection. Indigenously designed,
                        manufactured and state of art Remotely Operated Vehicle
                        (ROV). We are an IIT-Bombay Alumni start-up, mentored by
                        the incubation cell of IIT-Bombay “SINE” and supported
                        by the Ministry of electronics and information
                        technology. IXAR Robotic Solution covers a vast array of
                        Non-Destructive Testing , Structural diagnostics,
                        Post-inspection analysis and also provides customized
                        solutions.
                      </p>
                    </p>
                  </Fade>
                </div>
                <div class="col-sm-5" style={{ padding: "2.5% 0 0 0 " }}>
                  <div
                    className="container-fluid mb-5"
                    style={{ padding: "3% 2% 2% 2% " }}
                  >
                    <Fade right>
                      <Carousel>
                        <Carousel.Item>
                          <img
                            className="d-block w-100 "
                            src={ROVED}
                            alt="ROV"
                            width="100%"
                          
                          />
                          <Carousel.Caption></Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                          <img
                            className="d-block w-100 "
                            src={RROV}
                            alt="ROV2"
                            width="100%"
                          />

                          <Carousel.Caption></Carousel.Caption>
                        </Carousel.Item>
                      </Carousel>
                    </Fade>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* this is third conatiner */}
      <div>
        <div className="container">
          <div className="row">
            <h1>
              <strong>Remotely Operated Vehicle</strong>
            </h1>
            <Fade big>
              <hr
                style={{
                  width: "60%",
                  marginLeft: "20%",
                  border: "1px solid #4691ca",
                }}
              />
            </Fade>
            <Fade big>
              <Image
                src={ROVdetails}
                style={{ width: "100%", height: "100%" }}
                alt="ROV Details.jpg"
                className="py-3"
              />
            </Fade>
          </div> 
        </div>
      </div>

      {/* we cater */}

      {/* New Card */}
      <Container className="py-4 px-5">
        <h1>
          <strong>We Cater To</strong>
        </h1>
        <Zoom>
          {" "}
          <hr
            style={{
              width: "60%",
              marginLeft: "20%",
              border: "1px solid #4691ca",
            }}
          />
        </Zoom>
        {/* Row1 We Cater first 3 slide */}
        <Fade big>
          <Row className=" gy-3 py-4">
            <Col sm={4}>
              <CardDeck>
                <Card>
                  {/* <Card.Img variant="top" src={po} /> */}
                  <Image src={po} style={{ width: "100%", height: "100%" }} />
                
                  <Zoom>
                    <Card.Body className="text-center">
                      <Card.Title>PORT</Card.Title>
                      <Togglesp />
                    </Card.Body>
                  </Zoom>
                </Card>
              </CardDeck>
            </Col>

            <Col sm={4}>
              <CardDeck>
                <Card>
                  <Card.Img variant="top" src={pw} />
                  <Zoom>
                    <Card.Body className="text-center">
                      <Card.Title>POWER</Card.Title>
                      <Togglepp />
                    </Card.Body>
                  </Zoom>
                </Card>
              </CardDeck>
            </Col>
            <Col sm={4}>
              <CardDeck>
                <Card>
                  <Card.Img variant="top" src={dm} thumbnail />
                  <Zoom>
                    <Card.Body className="text-center">
                      <Card.Title>DAMS</Card.Title>
                      <Toggled />
                    </Card.Body>
                  </Zoom>
                </Card>
              </CardDeck>
            </Col>
          </Row>
        </Fade>
        {/* Row2 We Cater last 3 slide */}
        <Fade big>
          <Row className="gy-3">
            <Col sm={4}>
              <CardDeck>
                <Card>
                  <Card.Img variant="top" src={ol} />
                  <Zoom>
                    <Card.Body className="text-center">
                      <Card.Title>OIL & GAS</Card.Title>
                      <Toggleo />
                    </Card.Body>
                  </Zoom>
                </Card>
              </CardDeck>
            </Col>
            <Col sm={4}>
              <CardDeck>
                <Card>
                  <Card.Img variant="top" src={br} />
                  <Zoom>
                    <Card.Body className="text-center">
                      <Card.Title>BRIDGE</Card.Title>
                      <Toggleb />
                    </Card.Body>
                  </Zoom>
                </Card>
              </CardDeck>
            </Col>
            <Col sm={4}>
              <CardDeck>
                <Card>
                  <Card.Img variant="top" src={sh} />
                  <Zoom>
                    <Card.Body className="text-center">
                      <Card.Title>SHIP</Card.Title>
                      <Toggles />
                    </Card.Body>
                  </Zoom>
                </Card>
              </CardDeck>
            </Col>
          </Row>
        </Fade>
      </Container>

      {/* ROV SERVICES */}

      <Container className="py-3  ">
        <h1>
          {" "}
          <strong>ROV Services</strong>
        </h1>
        <Zoom>
          {" "}
          <hr
            style={{
              width: "60%",
              marginLeft: "20%",
              border: "1px solid #4691ca",
            }}
          />
        </Zoom>
        <Fade big>
          <Row className="pt-4 gy-3">
            <Col sm={4}>
              <CardDeck>
                <Card>
                  <Card.Img variant="top" src={vi} />
                  <Zoom>
                    <Card.Body className="text-center">
                      <Card.Title>Videoscope</Card.Title>
                    </Card.Body>
                    <Card.Title></Card.Title>
                    <Card.Title></Card.Title>
                    <Card.Title></Card.Title>
                  </Zoom>
                  <br />
                </Card>
              </CardDeck>
            </Col>
            <Col sm={4}>
              <CardDeck>
                <Card>
                  <Card.Img variant="top" src={ls} />
                  <Zoom>
                    <Card.Body className="text-center">
                      <Card.Title>Leser Scaling</Card.Title>
                    </Card.Body>

                    <Card.Title></Card.Title>
                    <Card.Title></Card.Title>
                    <Card.Title></Card.Title>
                  </Zoom>
                  <br />
                </Card>
              </CardDeck>
            </Col>
            <Col sm={4}>
              <CardDeck>
                <Card>
                  <Card.Img variant="top" src={tm} />
                  <Zoom>
                    <Card.Body className="text-center">
                      <Card.Title>
                        Thickness Measurements (UT & PECT) <br></br><br></br><br></br>
                      </Card.Title>
                    </Card.Body>
                  </Zoom>
                </Card>
              </CardDeck>
              <br />
            </Col>
          </Row>
        </Fade>
        <Fade big>
          <Row className="gy-3">
            <Col sm={4}>
              <CardDeck>
                <Card>
                  <Card.Img variant="top" src={sn} />
                  <Zoom>
                    <Card.Body className="text-center">
                      <Card.Title>Sonar</Card.Title>
                    </Card.Body>
                  </Zoom>
                  <br />
                </Card>
              </CardDeck>
            </Col>
            <Col sm={4}>
              <CardDeck>
                <Card>
                  <Card.Img variant="top" src={rd} />
                  <Zoom>
                    <Card.Body className="text-center">
                      <Card.Title>R & D as Per Customer Requirement<br></br><br></br></Card.Title>
                    </Card.Body>
                  </Zoom>
                </Card>
              </CardDeck>
            </Col>
            <Col sm={4}>
              <Card>
                <Card.Img variant="top" src={bth} />
                <Zoom>
                  <Card.Body className="text-center">
                    <Card.Title>Bathymetry</Card.Title>
                  </Card.Body>
                </Zoom>
                <br />
              </Card>
            </Col>
          </Row>
        </Fade>
      </Container>

      {/* Why US */}

      <Container className="px-5 py-3">
        <h1>
          <strong> Why Us</strong>
        </h1>
        <Zoom>
          <hr
            style={{
              width: "38%",
              marginLeft: "31%",
              border: "1px solid #4691ca",
            }}
          />
        </Zoom>
        <Fade big>
          <Row>
            <Col sm={4}>
              <p>
                <i
                  className="fab fa-telegram-plane"
                  style={{ color: "#4691ca", fontSize: "48px" }}
                ></i>
                <span style={{ fontSize: "1.5rem" }}> Quick Deployment</span>
                <h6 className="pt-2">
                  Easy and Portable Solution With Minimal Setup Time.{" "}
                </h6>
              </p>
            </Col>
            <Col sm={4}>
              <p>
                {" "}
                <i
                  class="fas fa-history"
                  style={{ color: "#4691ca", fontSize: "43px" }}
                ></i>{" "}
                <span style={{ fontSize: "1.5rem" }}> Working 24 x 7</span>{" "}
                <h6 className="pt-2">
                  Highly Efficient System Makes it Durable for Prolonged
                  Inspections.{" "}
                </h6>
              </p>
            </Col>
            <Col sm={4}>
              <p>
                {" "}
                <i
                  class="fas fa-wallet"
                  style={{ color: "#4691ca", fontSize: "43px" }}
                ></i>{" "}
                <span style={{ fontSize: "1.5rem" }}> Cost Effective </span>
                <h6 className="pt-2">
                  Indegenious Technology Helps us to Deliver High Valued Service
                  Affordable.{" "}
                </h6>
              </p>
            </Col>
          </Row>
        </Fade>
        <Fade big>
          <Row className="pb-4">
            <Col sm={4}>
              <i
                className="fas fa-exclamation-triangle "
                style={{ color: "#4691ca", fontSize: "43px" }}
              >
                {" "}
              </i>
              <span style={{ fontSize: "1.5rem" }}> Risk Free Operation</span>
              <h6 className="pt-2">
                Operatable in Hazardous and Confined Spaces.{" "}
              </h6>
            </Col>
            <Col sm={4}>
              <i
                class="fas fa-user-cog "
                style={{ color: "#4691ca", fontSize: "43px" }}
              ></i>
              <span style={{ fontSize: "1.5rem" }}> Tailored Solutions</span>
              <h6 className="pt-2">
                Indegenuity and R&D Resources Delivers Optimised Solutions.
              </h6>
            </Col>
            <Col sm={4}>
              <i
                class="fas fa-tools"
                style={{ color: "#4691ca", fontSize: "43px" }}
              ></i>
              <span style={{ fontSize: "1.5rem" }}> In House Maintence</span>
              <h6 className="pt-2">
                Designed and Developed in India with an Aim to Promote Make In
                India.
              </h6>
            </Col>
          </Row>
        </Fade>
      </Container>
      {/* the boxx */}
      <div
        className="container text-center px-5"
        // style={{ textAlign: "center", paddingTop: "1%" }}
      >
        <div className="row py-4">
          <h1>
            <strong>Our Supporters</strong>
          </h1>
          <Zoom>
            <hr
              style={{
                width: "38%",
                marginLeft: "31%",
                border: "1px solid #4691ca",
              }}
            />
          </Zoom>
          <Fade big>
          {" "}
            <div className="col-sm-4 py-3">
              <a href="https://www.sineiitb.org/" target="_blank">
                <img
                  src={sine}
                  alt="Sine.jpg"
                  height="35%"
                  width="35%"
                  className="img-fluid"
                /> 
              </a>
            </div>
            <div className="col-sm-4 py-2">
              <a href="https://www.meity.gov.in/" target="_blank">
                <img
                  src={mitg}
                  alt="MITG.jpg"
                  height="60%"
                  width="60%"
                  className="img-fluid "
                />
              </a>
            </div>
            <div className="col-sm-4 py-2">
            {" "}
              <a href="https://www.msme.gov.in/" target="_blank">
                <img
                  src={msme}
                  alt="IIT.jpg"
                  height="40%"
                  width="40%"
                  className="img-fluid"
                />
              </a>
            </div>
            <div className="col-sm-4 py-2">
              <a href="https://www.iitb.ac.in/" target="_blank">
                <img
                  src={iit}
                  alt="TIH.jpg"
                  height="35%"
                  width="25%"
                  className="img-fluid "
                />
              </a>
            </div>
            <div className="col-sm-4 py-2">
            {" "}
              <a href="https://www.startupindia.gov.in/" target="_blank">
                <img
                  src={india}
                  alt="msme.jpg"
                  height="60%"
                  width="60%"
                  className="img-fluid"
                />
              </a>
            </div>
            <div className="col-sm-4 py-2">
              <a href="https://www.tih.iitb.ac.in/" target="_blank">
                <img
                  src={tih}
                  alt="india.png"
                  height="50%"
                  width="40%"
                  className="img-fluid "
                />
              </a>
            </div>
          </Fade>
        </div>
      </div>

      <div
        className="container-fluid"
        style={{
          backgroundColor: "#141414",
          color: "white",
          textAlign: "center",
        }}
      >
        <div className="row">
          <div className="col-md-4">
            <img src={contactrov} alt="ROV.jpg" width="100%" height="100%" />
          </div>
          <div className="col-md-5 offset-md-2">
            <div className="row">
              <ha>Address</ha>
            </div>
            <div className="row">
              <Fade right>
                <hb>
                  102, Faizan Apartment, S. V. Road, Jogeshwari (West), Mumbai
                  400-102, Maharashtra, India
                </hb>
              </Fade>
            </div>
            <div className="row">
              <ha>Phone Number</ha>
            </div>
            <Fade right>
              <div className="row">
                <a href="tel: 9930652916" style={{ textDecoration: "none" }}>
                  <a style={{ color: "white" }}>+91 9930652916 </a>
                </a>
              </div>
              <div className="row">
                <a
                  href="tel: +91-8591327171"
                  style={{ textDecoration: "none" }}
                >
                  <a style={{ color: "white" }}>+91-8591327171</a>
                </a>
              </div>
            </Fade>
            <div className="row">
              <ha>Email Address:</ha>
            </div>
            <Fade right>
              <div className="row">
                <a
                  href="mailto: info@ixarrobotic.com"
                  style={{ textDecoration: "none" }}
                >
                  <a style={{ color: "white" }}> info@ixarrobotic.com</a>
                </a>
              </div>
              <div className="row">
                <a
                  href="mailto: ixarroboticsolutions@gmail.com"
                  style={{ textDecoration: "none" }}
                >
                  <a style={{ color: "white" }}>
                    ixarroboticsolutions@gmail.com
                  </a>
                </a>
              </div>
            </Fade>
          </div>
        </div>
      </div>

      {/* <div className="icon-bar">
        <a
          href="https://www.instagram.com/ixar_robotic_solutions/"
          target="_blank"
          className="fa fa-instagram"
        ></a>
        <a
          href="https://www.facebook.com/IXAR-Robotics-Solutions-123677916277647"
          target="_blank"
          className="fa fa-facebook"
        ></a>

        <a
          href="https://www.linkedin.com/in/ixar-robotic-solution-209004212"
          target="_blank"
          className="fa fa-linkedin"
        ></a>
        <a
          href="https://twitter.com/IxarRobotics?s=08"
          target="_blank"
          className="fa fa-twitter"
        ></a>

        <a
          href="https://youtube.com/channel/UC8aWp3FRu_p-UzpAsHX8jXg"
          target="_blank"
          className="fa fa-youtube"
        ></a>

        <a
          href="https://youtube.com/channel/UC8aWp3FRu_p-UzpAsHX8jXg"
          target="_blank"
          className="fa fa-envelope"
        ></a>
      </div> */}
    </>
  );
}

export default Home;
