import React from "react";
import "./CSS/Footer.css"
import logo from "./Images/logo1.jpg";

function Footer() {
  return (
    <div className="footer-container">
      <section className="social-media">
        <div className="container">
          <div className="row">
            <div className="col">
              <a to="/">
                <img src={logo} alt="" width="170" height="70" />
              </a>
            </div>

            <div className="col d-flex justify-content-end  py-2 ">
              <a
                href="https://www.facebook.com/IXAR-Robotics-Solutions-123677916277647"
                target="_blank"
                class="fa fa-facebook"
              ></a>
              <a
                href="https://twitter.com/IxarRobotics?s=08"
                target="_blank"
                class="fa fa-twitter"
              ></a>
              <a
                href="https://www.linkedin.com/in/ixar-robotic-solution-209004212"
                target="_blank"
                class="fa fa-linkedin"
              ></a>
              <a
                href="https://youtube.com/channel/UC8aWp3FRu_p-UzpAsHX8jXg"
                target="_blank"
                class="fa fa-youtube"
              ></a>
              <a
                href="https://www.instagram.com/ixar_robotic_solutions/"
                target="_blank"
                class="fa fa-instagram"
              ></a>
            </div>
          </div>

          <div className="row">
            {" "}
            <small className="website-center">
              © Made with Passion and Dedication from 2021 IXAR Robotics Team.
              All Rights Reserved
            </small>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
