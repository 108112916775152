import React from "react";
import "./CSS/TeamM.css";
import CardItem from "./Pages/CardItem.jsx";
import Helmet from "react-helmet";
import rishi from "./Images/Rishi.jpg";
import vipin from "./Images/vipin.webp";
import mahesh from "./Images/mahesh.jpeg";
import suraj from "./Images/Surajj.jpeg";
import fahad from "./Images/fahad.jpeg";
import dhruvik from "./Images/dhruvik.jpeg";
import ashish from "./Images/ashish.webp";
import arpit from "./Images/arpit.jpg";
import rohit from "./Images/rohit.jpeg";
import anilsir from "./Images/anilsir.jpeg";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";

function TeamM() {
  return (
    <>
      <Helmet>
        <title>IXAR ROBOTIC SOLUTIONS | TEAM | CAREER </title>
        <meta name="description" content="Ixar Robotic Solutions | Team" />
        <meta
          name="Keywords"
          content="breakwater inspection,jetty inspection,wharf inspection,riser inspection, Seawater Intake/Outfall Pipeline Inspection,thermal plant tank inspection,water tank inspection,water sump inspection"
        />
      </Helmet>
      <div className="hero-containert">
        <h1>Team</h1>
      </div>

      {/* Start Previous One */}

      <div className="cardsm">
        <div className="cardsm">
          <h2
            style={{
              padding: "1%",
              fontSize: "37px",
              color: "rgb(70, 145, 202)",
            }}
          >
            <strong>Founder</strong>
            <Zoom>
              <hr
                style={{
                  width: "40%",
                  marginLeft: "30%",
                  border: "1px solid #4691ca",
                }}
              />
            </Zoom>
          </h2>

          <div className="cards__containerf pb-8">
            <div className="cards__wrapperm">
              <ul className="cards__itemsm">
                <Zoom>
                  <CardItem
                    src={rishi}
                    text="Rishi Jain"
                    name="B-Tech Metallurgy and Material Science IIT Bombay 2019"
                    label="Founder"
                    path="/team"
                  />
                </Zoom>
              </ul>
            </div>
          </div>
        </div>

        <div className="cardsm ">
          <h2
            style={{
              padding: "1%",
              fontSize: "35px",
              color: "rgb(70, 145, 202)",
            }}
          >
            <strong>Team</strong>
            <Zoom>
              <hr
                style={{
                  width: "40%",
                  marginLeft: "30%",
                  border: "1px solid #4691ca",
                }}
              />
            </Zoom>
          </h2>
          <div className="cards__containert">
            <div className="cards__wrapperm">
              <ul className="cards__itemsm">
                <Fade left>
                <CardItem
                    src={dhruvik}
                    text="Dhruvik Poriya"
                    name="BTech - Mechanical Engineering Nirma University 2021 &nbsp;&nbsp;
                    "
                    label="Technical Manager"
                    path="/team"
                  />
                </Fade>
                <Zoom>
                  <CardItem
                    src={fahad}
                    text="Fahad Baig"
                    name="BTech - Mechanical Engineering Integral University 2021
                    "
                    label="Operation Manager"
                    path="/team"
                  />
                </Zoom>
                <Fade right>
                <CardItem
                    src={mahesh}
                    text="Mahesh K M"
                    name="Diploma - Electronics Engineering &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SBTE 2018 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    "
                    label="Product Developer"
                    path="/team"
                  />
                </Fade>
              </ul>
            </div>
          </div>
          <Zoom>
            <div className="cards__containerm">
              <div className="cards__wrapper">
                <ul className="cards__items">
                  <CardItem
                    src={arpit}
                    text="Arpit Seth"
                    name="Bcom - Mumbai University 2021 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                    label="Account Executive"
                    path="/team"
                  />
                  <CardItem
                    src={suraj}
                    text="Suraj Yadav"
                    name="Bsc - IT Mumbai University 2020 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                    label="Web Developer"
                    path="/team"
                  />
                </ul>
              </div>
            </div>
          </Zoom>
        </div>
        

        <h2
          style={{
            padding: "1%",
            fontSize: "35px",
            color: "rgb(70, 145, 202)",
          }}
        >
          <strong>Our Mentors</strong>
        </h2>
        <Zoom>
          <hr
            style={{
              width: "38%",
              marginLeft: "31%",
              border: "1px solid #4691ca",
            }}
          />
        </Zoom>

        <Fade big>
          <div className="cards__containerm">
            <div className="cards__wrapper">
              <ul className="cards__items">
                <CardItem
                  src={anilsir}
                  text="Anil Jain "
                  name="Managing Director IXAR"
                  label="Mentor"
                  path="/team"
                />
                <CardItem
                  src={rohit}
                  text="Rohit Jain "
                  name="Pursuing - PhD from University of Houston, USA
                "
                  label="Technical Mentor"
                  path="/team"
                />
              </ul>
            </div>
          </div>
        </Fade>
      </div>
    </>
  );
}

export default TeamM;
