import React from "react";
import "../App.css";
import emailjs from "emailjs-com";
import "./CSS/Prdts.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import contact from "./Images/contact.webp";
import address from "./Images/contactrov.webp";
import Helmet from "react-helmet";

import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import Zoom from "react-reveal/Zoom";

export default function Contact() {
  const notify = () => toast("Sending....");
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "gmail",
        "template_u71d037",
        e.target,
        "user_Laopcfcr7Jrs4mhYOGItr"
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("Send Successfull", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        },
        (error) => {
          console.log(error.text);
          toast.error(error.text);
          toast.warn("Error", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      );
    e.target.reset();
  }

  return (
    <>
      <Helmet>
        <title>IXAR ROBOTIC SOLUTIONS | CONTACT | INQUERY | CONTACT-US</title>
        <meta
          name="description"
          content="for any inquery or details call us 24X7, 8591327171"
        />
        <meta
          name="Keywords"
          content="ixar robotic solution, ixar, ixar robotic, contact-us, inspection support during repairs,
        sonar survey & bathymetry,pre & post repair inspection,bridge pier inspection,bio-fouling inspection,ship propeller inspection,ballast tank inspection,inspection of paint loss,ship hull inspection"
        />
      </Helmet>
      <ToastContainer />
      <section className="page-section" id="contact">
        <img src={contact} class="image-fluid" alt="Contact.jpg" width="100%" />

        <div className="container-fluid">
          <div className="text-center">
            <h2
              className="section-heading "
              style={{ padding: "2% 0px 0px 0px" }}
            >
              <hc1>
                <strong>Contact Us</strong>
              </hc1>
            </h2>
            <h3
              className="section-subheading "
              style={{ padding: "0px 0px  7px 0px" }}
            >
              <hc2>We Will Get Back to you as Soon as Possible. </hc2>
            </h3>
          </div>

          <form
            id="contactForm"
            name="sentMessage"
            className="px-2  py-2"
            onSubmit={sendEmail}
          >
            <div className="row  mb-5 ">
              <div className="col-md-6 ">
                <Fade big>
                  <div className="form-group">
                    <input
                      className="form-control"
                      id="name"
                      type="text"
                      placeholder="Your Name *"
                      required="required"
                      data-validation-required-message="Please enter your name."
                      name="name"
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </Fade>

                <Fade big>
                  <div className="form-group">
                    <input
                      className="form-control"
                      id="email"
                      type="email"
                      placeholder="Your Email *"
                      required="required"
                      data-validation-required-message="Please enter your email address."
                      name="email"
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </Fade>

                <Fade big>
                  <div className="form-group mb-md-0">
                    <input
                      className="form-control"
                      id="phone"
                      type="tel"
                      placeholder="Your Phone *"
                      required="required"
                      data-validation-required-message="Please enter your phone number."
                      name="phone"
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </Fade>
              </div>

              <Fade big>
                <div className="col-md-6">
                  <div className="form-group form-group-textarea mb-md-0">
                    <textarea
                      className="form-control"
                      id="message"
                      rows="8"
                      placeholder="Your Message *"
                      required="required"
                      data-validation-required-message="Please enter a message."
                      name="message"
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
              </Fade>
            </div>

            <div className="text-center">
              <div id="success"></div>
              <div>
                <Zoom>
                  <div className="container">
                    <button
                      className="btn btn-primary btn-xl text-uppercase"
                      id="sendMessageButton"
                      type="submit"
                      onClick={notify}
                    >
                      <strong>Send Message</strong>
                    </button>
                    <div style={{ padding: "2% 0 0 2%" }}></div>
                  </div>
                </Zoom>
                <ToastContainer />
              </div>
            </div>
          </form>
        </div>
      </section>

      <div
        className="container-fluid"
        style={{
          backgroundColor: "#141414",
          color: "white",
          textAlign: "center",
        }}
      >
        <div className="row">
          <div className="col-md-4">
            <img src={address} alt="ROV.jpg" width="100%" height="100%" />
          </div>
          <div className="col-md-5 offset-md-2">
            <div className="row">
              <ha>Address</ha>
            </div>
            <Fade right>
              <div className="row">
                <hb>
                  102, Faizan Apartment, S. V. Road, Jogeshwari (West), Mumbai
                  400-102, Maharashtra, India
                </hb>
              </div>
            </Fade>
            <div className="row">
              <ha>Phone Number</ha>
            </div>
            <Fade right>
              <div className="row">
                <a href="tel: 9930652916" style={{ textDecoration: "none" }}>
                  <a style={{ color: "white" }}>+91 9930652916 </a>
                </a>
              </div>
              <div className="row">
                <a
                  href="tel: +91-8591327171"
                  style={{ textDecoration: "none" }}
                >
                  <a style={{ color: "white" }}>+91-8591327171</a>
                </a>
              </div>
            </Fade>
            <div className="row">
              <ha>Email Address:</ha>
            </div>
            <Fade right>
              <div className="row">
                <a
                  href="mailto: info@ixarrobotic.com"
                  style={{ textDecoration: "none" }}
                >
                  <a style={{ color: "white" }}> info@ixarrobotic.com</a>
                </a>
              </div>
              <div className="row">
                <a
                  href="mailto:ixarroboticsolutions@gmail.com"
                  style={{ textDecoration: "none" }}
                >
                  <a style={{ color: "white" }}>
                    ixarroboticsolutions@gmail.com
                  </a>
                </a>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </>
  );
}
