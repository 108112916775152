import React from "react";
import "./CSS/Prdts.css"
import "./CSS/About.css";
import "../App.css"
import emailjs from "emailjs-com";
import { AiTwotoneStar, AiOutlineFlag } from "react-icons/ai";
import { ImRocket } from "react-icons/im";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CSS/HeroSection.css"
import Helmet from "react-helmet";
import { BsFillPlusCircleFill } from "react-icons/bs";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";

export default function Patner() {
  const notify = () => toast("Sending....");

  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "gmail",
        "template_fnb2qpb",
        e.target,
        "user_Laopcfcr7Jrs4mhYOGItr"
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("Send Successfull", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        },
        (error) => {
          console.log(error.text);
          toast.error(error.text);
          toast.warn("Error", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      );
    e.target.reset();
  }
  return (
    <>
      <Helmet>
        <title>IXAR ROBOTIC SOLUTIONS | Partner With Us</title>
        <meta
          name="description"
          content="Ixar Robotic Solutions | Partner With US"
        />
        <meta name="Keywords" content="partner with ixar robotic solutions" />
      </Helmet>
      <div className="hero-containerpp">
        <h1>
          <strong>Partner With Us</strong>
        </h1>
      </div>

      <div className="aboutcss">
        <div
          className="container"
          style={{ padding: "4% 4% 0px 4%", textAlign: "center" }}
        >
          <div
            className="row border rounded overflow-hidden"
            style={{ padding: "2% 0% 2% 0%" }}
          >
            <h1 style={{ padding: "0px 0px 4% 0px", textAlign: "center" }}>
              <strong> Why Partner With Us </strong>
            </h1>

            <Zoom>
              <div className="col-md-3">
                <AiTwotoneStar
                  style={{
                    fontSize: "3.5rem",
                    margin: "0px 0px 15% 0px",
                    color: "#4691ca",
                  }}
                />
                <h6>
                  {" "}
                  <p>
                    Pioneer in the ROV Industry that has Developed Products with
                    Global Standard.{" "}
                  </p>
                </h6>
              </div>
            </Zoom>
            <Zoom>
              <div className="col-md-3">
                <AiOutlineFlag
                  style={{
                    fontSize: "3.5rem",
                    margin: "0px 0px 15% 0px",
                    color: "#4691ca",
                  }}
                />
                <h6>
                  {" "}
                  <p>
                    Market Leader in the ROV Industry in India for
                    Non-Destructive Testing.{" "}
                  </p>{" "}
                </h6>
              </div>
            </Zoom>
            <Zoom>
              <div className="col-md-3">
                <BsFillPlusCircleFill
                  style={{
                    fontSize: "3.5rem",
                    margin: "0px 0px 15% 0px",
                    color: "#4691ca",
                  }}
                  // style={{ fontSize: "3.5rem", margin: "0px 0px 15% 0px",color:"#4691ca" }}
                />

                <h6>
                  {" "}
                  <p>
                    Technical Support from Ministry of Electronics and
                    Information Technology, India and IIT Bombay.{" "}
                  </p>
                </h6>
              </div>
            </Zoom>
            <Zoom>
              <div className="col-md-3">
                <ImRocket
                  style={{
                    fontSize: "3.5rem",
                    margin: "0px 0px 15% 0px",
                    color: "#4691ca",
                  }}
                />
                <h6>
                  {" "}
                  <p>
                    Highly agile Organization Capable of Meeting Customer
                    Requirements.{" "}
                  </p>
                </h6>
              </div>
            </Zoom>
          </div>
        </div>
      </div>

      {/* who can become partner */}

      <div className="aboutcss">
        <div
          className="container "
          style={{ padding: "0% 4% 4% 4%", textAlign: "center" }}
        >
          <div
            className="row border rounded overflow-hidden"
            style={{ padding: "0% 0% 5% 0%" }}
          >
            <h1 style={{ padding: "0px 0px 4% 0px", textAlign: "center" }}></h1>
            <h1 style={{ padding: "1% 0% 3% 0%", color: " #4691ca" }}>
              <strong>Who Can Become A Partner?</strong>
            </h1>

            <Fade left>
              <ul
                style={{
                  padding: "1% 0% 0% 10%",
                  textAlign: "left",
                  fontSize: "18px",
                }}
              >
                <Fade left>
                  <h6>
                    <p>
                      {" "}
                      <li> Passionate about Technology and Innovation. </li>
                    </p>
                  </h6>
                </Fade>

                <Fade left>
                  <h6>
                    {" "}
                    <p>
                      <li>
                        {" "}
                        Great Connect with Government with Private Enterprises.{" "}
                      </li>
                    </p>
                  </h6>
                </Fade>
                <Fade left>
                  <h6>
                    <p>
                      {" "}
                      <li>
                        People Champion with a Customer-Centric Approach.
                      </li>{" "}
                    </p>
                  </h6>
                </Fade>
                <Fade left>
                  <h6>
                    {" "}
                    <li>Aligned with our values and Aspiration. </li>
                  </h6>
                </Fade>
              </ul>
            </Fade>
          </div>
        </div>
      </div>

      <section className="page-section" id="contactp">
        <div className="container-fluid">
          <div className="text-center">
            <h2
              className="section-heading  px-5"
              style={{ padding: "2% 0px 0px 0px" }}
            >
              <hb1>
                <strong>Partner With Us</strong>{" "}
              </hb1>
            </h2>
            <h3
              className="section-subheading "
              style={{ padding: "0px 0px  7px 0px" }}
            >
              <hb2>Tell us a bit about Yourself</hb2>
            </h3>
          </div>
          <Fade big>
            <form id="contactForm" name="sentMessage" onSubmit={sendEmail}>
              <div className="container-fluid row align-items-stretch mb-5">
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      className="form-control"
                      id="title"
                      type="text"
                      placeholder="Title"
                      required="required"
                      data-validation-required-message="Please enter your Title."
                      name="title"
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      className="form-control"
                      id="name"
                      type="text"
                      placeholder="Name"
                      required="required"
                      data-validation-required-message="Please enter your name."
                      name="name"
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      className="form-control"
                      id="email"
                      type="email"
                      placeholder="Email"
                      required="required"
                      data-validation-required-message="Please enter your email address."
                      name="email"
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-md-0">
                    <input
                      className="form-control"
                      id="phone"
                      type="tel"
                      placeholder="Phone"
                      required="required"
                      data-validation-required-message="Please enter your phone number."
                      name="phone"
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      className="form-control"
                      id="designation"
                      type="text"
                      placeholder="Designation"
                      required="required"
                      data-validation-required-message="Please enter your designation."
                      name="designation"
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-md-0">
                    <input
                      className="form-control"
                      id="companywebsite"
                      type="text"
                      placeholder="Company Website"
                      required="required"
                      data-validation-required-message="Please enter your company website."
                      name="companywebsite"
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>

                <div className="col">
                  <div className="form-group mb-md-0">
                    <input
                      className="form-control"
                      id="companyad"
                      type="text"
                      placeholder="Company Address"
                      required="required"
                      data-validation-required-message="Please enter your company address"
                      name="companyad"
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
                <div className="container">
                  <div className="col">
                    <div className="form-group mb-md-0">
                      <textarea
                        className="form-control"
                        id="message"
                        placeholder="Message"
                        required="required"
                        data-validation-required-message="Please enter a message."
                        name="message"
                        rows="4"
                      ></textarea>
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid">
                <div className="text-center">
                  <div id="success"></div>
                  <div>
                    <div className="container-fluid">
                      <button
                        className="btn btn-primary btn-xl text-uppercase"
                        id="sendMessageButton"
                        type="submit"
                        onClick={notify}
                      >
                        <strong>Send Message</strong>
                      </button>
                      <div style={{ padding: "2% 0 0 2%" }}></div>
                    </div>
                    <ToastContainer />
                  </div>
                </div>
              </div>
            </form>
          </Fade>
        </div>
      </section>
    </>
  );
}
