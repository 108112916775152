import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./CSS/NavBar.css";
import Helmet from "react-helmet";
import logo from "./Images/logo1.jpg";
function NavBar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);


  return (
    <>
      <Helmet>
        <title>
          IXAR ROBOTIC SOLUTIONS - Underwater ROV Services | Underwater ROV
          Inspections | ROV Inspection Services
        </title>
        <meta
          name="description"
          content="IXAR Robotic Solutions covers a vast array of Non-Destructive Testing. 
      We are a one stop solution for all underwater water survey and inspections."
        />
        <meta name="Keywords" content="underwater ROV inspections" />
      </Helmet>
      {/* ...................... */}
      <div class="icon-barsd">
      
        {/* <a href="#" class="facebook"><i class="fa fa-facebook"></i></a>  */}
        <a href="tel: 9930652916" class="phone"><i class="fa fa-phone"></i></a> 
        <a href="https://wa.link/mooojm" class="WhatsApp"><i class="fa fa-whatsapp"></i></a> 
        <a href="mailto: info@ixarrobotic.com" class="mail"><i class="fa fa-envelope"></i></a>
        
      </div>
     

      {/*........................*/}
      <nav className="navbar">
        <div className="nav-container">
   
          <NavLink exact to="/" className="nav-logo">
            <img src={logo} alt="" width="150" height="60" />
          </NavLink>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/home"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                <span>H</span>ome
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/services"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                <span>S</span>ervices
              </NavLink>
            </li>
            {/* ............................. */}
            {/*
            <li className="dropdown">
              <NavLink
                exact
                to="/product"
                // activeClassName="active"
                 className="nav-links"
                onClick={handleClick}
              >
                <button class="dropbtn"><span>P</span>roduct
                  </button>
                  <div class="dropdown-content">
                  <a      >Rov 1.0</a>
                  <a to="/services">Rov Pro</a>
                </div>&nbsp;&nbsp;&nbsp;&nbsp;
              
              </NavLink>
            </li>     
             */}
           
            <div class="dropdown">
               <button class="dropbtn"><span>P</span>roduct
                  {/* <i class="fa fa-caret-down"></i>  */}
               </button>
              <div class="dropdown-content">
                <NavLink
                  exact
                  to="/product"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  <a to="/product">ROV 1.0</a>
                </NavLink>

                <NavLink
                  exact
                  to="/Rovpro"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  <a to="/services">ROV Pro</a>
                </NavLink>
                    
              </div>
            </div> 
            
          
            {/* ........................... */}
            <li className="nav-item">
              <NavLink
                exact
                to="/team"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                <span>T</span>eam
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/contact"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                <span>C</span>ontact us
              </NavLink>
            </li>

            <li className="nav-item">
              <a href="tel: 9930652916">
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  style={{
                    color: "rgb(0, 195, 255)",
                    border: "1.5px solid  rgb(0, 195, 255)",
                  }}
                >
                  <strong>Call</strong>
                </button>{" "}
              </a>
            </li>
            <li className="nav-item">
              <a href="mailto: info@ixarrobotic.com">
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  style={{
                    color: "rgb(0, 195, 255)",
                    border: "1.5px solid  rgb(0, 195, 255)",
                  }}
                >
                  <strong>Email</strong>
                </button>
              </a>
            </li>

            <li className="nav-item">
              <NavLink
                exact
                to="/Partner"
                activeClassName="active"
                // className="nav-links"
                onClick={handleClick}
              >
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  style={{
                    color: "rgb(0, 195, 255)",
                    border: "1.5px solid  rgb(0, 195, 255)",
                  }}
                >
                  <strong>Partner With Us</strong>
                </button>
              </NavLink>
            </li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
