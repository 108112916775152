import React, { useState, Component } from "react";

import ReactDOM from "react-dom";

function Message(props) {
  if (props.isLoggedIn) return <h6>Minimise</h6>;
  else return <h6>Click for Details</h6>;
}
function Login(props) {
  return (
    <button type="button" class="btn btn-danger" onClick={props.clickFunc}>
      Open
    </button>
  );
}
function Logout(props) {
  return (
    <button type="button" class="btn btn-danger" onClick={props.clickFunc}>
      Close
    </button>
  );
}
export default class Toogles extends Component {
  constructor(props) {
    super(props);

    this.state = { isLoggedIn: false };

    this.ifLoginClicked = this.ifLoginClicked.bind(this);
    this.ifLogoutClicked = this.ifLogoutClicked.bind(this);
  }

  ifLoginClicked() {
    this.setState({ isLoggedIn: true });
  }

  ifLogoutClicked() {
    this.setState({ isLoggedIn: false });
  }

  state = {
    on: false,
  };
  toggle = () => {
    this.setState({
      on: !this.state.on,
    });
  };
  render() {
    return (
      <div>
        {this.state.on && (
          <div className="row mb-2">
            <div>
              <div className="ml-2" style={{ textAlign: "left" }}>
                <ul>
                  <li> Bio-Fouling Inspection</li>
                  <li>Ship Propeller Inspection</li>
                  <li> Ballast Tank Inspection</li>
                  <li>Inspection of Paint Loss</li>
                  <li>Ship Hull Inspection</li>
                </ul>
              </div>
              <div class="col-auto d-none d-lg-block">
                <div>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        )}
        <div>
          <Message isLoggedIn={this.state.isLoggedIn} />

          {this.state.isLoggedIn ? (
            <button
              type="button"
              class="btn btn-danger"
              onClick={this.toggle}
              clickFunc={this.ifLogoutClicked}
            >
              <Logout clickFunc={this.ifLogoutClicked} />{" "}
            </button>
          ) : (
            <button
              type="button"
              class="btn btn-danger"
              onClick={this.toggle}
              clickFunc={this.ifLoginClicked}
            >
              <Login clickFunc={this.ifLoginClicked} />
            </button>
          )}
        </div>
      </div>
    );
  }
}
