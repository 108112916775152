import React from "react";
import "./CSS/Servc.css";
import Togglesp from "./Pages/Tooglep.jsx";
import Toggleb from "./Pages/Toogleb.jsx";
import Toggled from "./Pages/Toggled.jsx";
import Toggleo from "./Pages/Toggleo.jsx";
import Togglepp from "./Pages/Togglepp.jsx";
import Toggles from "./Pages/Toggles.jsx";
import Helmet from "react-helmet";
import po from "./Images/ports.jpg";
import pw from "./Images/Thermal.jpg";
import dm from "./Images/dam.webp";
import ol from "./Images/oil.jpg";
import br from "./Images/bridge.webp";
import sh from "./Images/shi.jpg";
import vi from "./Images/Inspection.jpg";
import ls from "./Images/laser.webp";
import tm from "./Images/ut.webp";
import sn from "./Images/radar.jpg";
import rd from "./Images/RD.jpg";
import bth from "./Images/bath.JPG";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import Zoom from "react-reveal/Zoom";
import { CardDeck, Col, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
});

function Servc() {
  const classes = useStyles();
  return (
    <>
      <Helmet>
        <title>
          IXAR ROBOTIC SOLUTIONS | India's Best Inspection Services Provider |
          Underwater ROV Services | SERVICES
        </title>
        <meta
          name="description"
          content="India's Best Inspection Services Provider | India's Best Underwater Sonar Surveys Provider"
        />
        <meta
          name="Keywords"
          content="videoscope, leser scaling, thickness measurements (ut & pect), sonar, r & d as per customer requirement, bathymetry,submerged cable inspection,fire water tank inspection,offshore installation support,submerged pipeline inspection"
        />
      </Helmet>
      <div className="hero-containers">
        <h1>Services</h1>
      </div>

      <Container className="py-3 px-5">
        <h1>
          {" "}
          <strong>ROV Services</strong>
        </h1>
        <Zoom>
          {" "}
          <hr
            style={{
              width: "60%",
              marginLeft: "20%",
              border: "1px solid #4691ca",
            }}
          />
        </Zoom>
        <Fade big>
          <Row className="py-3 gy-4">
            <Col sm={4}>
              <CardDeck>
                <Card>
                  <Card.Img variant="bottom" src={vi} />
                  <Zoom>
                    <Card.Body className="text-center">
                      <Card.Title>Videoscope</Card.Title>
                    </Card.Body>
                  </Zoom>
                  <br />
                </Card>
              </CardDeck>
            </Col>
            <Col sm={4}>
              <CardDeck>
                <Card>
                  <Card.Img variant="bottom" src={ls} />
                  <Zoom>
                    <Card.Body className="text-center">
                      <Card.Title>Leser Scaling</Card.Title>
                    </Card.Body>
                  </Zoom>
                  <br />
                </Card>
              </CardDeck>
            </Col>
            <Col sm={4}>
              <CardDeck>
                <Card>
                  <Card.Img variant="bottom" src={tm} />
                  <Zoom>
                    <Card.Body className="text-center">
                      <Card.Title>
                        Thickness Measurements (UT & PECT)<br></br><br></br>
                      </Card.Title>
                    </Card.Body>
                  </Zoom>
                </Card>
              </CardDeck>
            </Col>
          </Row>
        </Fade>
        <Fade big>
          <Row className="gy-3">
            <Col sm={4}>
              <CardDeck>
                <Card>
                  <Card.Img variant="bottom" src={sn} />
                  <Zoom>
                    <Card.Body className="text-center">
                      <Card.Title>Sonar</Card.Title>
                    </Card.Body>
                  </Zoom>
                  <br />
                </Card>
              </CardDeck>
            </Col>
            <Col sm={4}>
              <CardDeck>
                <Card>
                  <Card.Img variant="bottom" src={rd} />
                  <Zoom>
                    <Card.Body className="text-center">
                      <Card.Title>R & D as Per Customer Requirement <br></br><br></br></Card.Title>
                    </Card.Body>
                  </Zoom>
                </Card>
              </CardDeck>
            </Col>
            <Col sm={4}>
              <CardDeck>
                <Card>
                  <Card.Img variant="bottom" src={bth} />
                  <Zoom>
                    <Card.Body className="text-center">
                      <Card.Title>Bathymetry</Card.Title>
                    </Card.Body>
                  </Zoom>
                  <br />
                </Card>
              </CardDeck>
            </Col>
          </Row>
        </Fade>
      </Container>

      {/* New Card */}
      <Container className="py-5 px-5">
        <h1>
          <strong>We Cater To</strong>
        </h1>
        <Zoom>
          {" "}
          <hr
            style={{
              width: "60%",
              marginLeft: "20%",
              border: "1px solid #4691ca",
            }}
          />
        </Zoom>
        {/* Row1 We Cater first 3 slide */}
        <Fade big>
          <Row className="py-3 gy-3">
            <Col sm={4}>
              <CardDeck>
                <Card>
                  {/* <Card.Img variant="bottom" src={po} /> */}
                  <Image src={po} style={{ width: "100%", height: "100%" }} />
                  <Zoom>
                    <Card.Body className="text-center">
                      <Card.Title>PORT</Card.Title>
                      <Togglesp />
                    </Card.Body>
                  </Zoom>
                </Card>
              </CardDeck>
            </Col>

            <Col sm={4}>
              <CardDeck>
                <Card>
                  <Card.Img variant="bottom" src={pw} />
                  <Zoom>
                    <Card.Body className="text-center">
                      <Card.Title>POWER</Card.Title>
                      <Togglepp />
                    </Card.Body>
                  </Zoom>
                </Card>
              </CardDeck>
            </Col>
            <Col sm={4}>
              <CardDeck>
                <Card>
                  <Card.Img variant="bottom" src={dm} thumbnail />
                  <Zoom>
                    <Card.Body className="text-center">
                      <Card.Title>DAMS</Card.Title>
                      <Toggled />
                    </Card.Body>
                  </Zoom>
                </Card>
              </CardDeck>
            </Col>
          </Row>
        </Fade>
        <Fade big>
          {/* Row2 We Cater last 3 slide */}
          <Row className="gy-3">
            <Col sm={4}>
              <CardDeck>
                <Card>
                  <Card.Img variant="bottom" src={ol} />
                  <Zoom>
                    <Card.Body className="text-center">
                      <Card.Title>OILS & GAS</Card.Title>
                      <Toggleo />
                    </Card.Body>
                  </Zoom>
                </Card>
              </CardDeck>
            </Col>
            <Col sm={4}>
              <CardDeck>
                <Card>
                  <Card.Img variant="bottom" src={br} />
                  <Zoom>
                    <Card.Body className="text-center">
                      <Card.Title>BRIDGE</Card.Title>
                      <Toggleb />
                    </Card.Body>
                  </Zoom>
                </Card>
              </CardDeck>
            </Col>
            <Col sm={4}>
              <CardDeck>
                <Card>
                  <Card.Img variant="bottom" src={sh} />
                  <Zoom>
                    <Card.Body className="text-center">
                      <Card.Title>SHIP</Card.Title>
                      <Toggles />
                    </Card.Body>
                  </Zoom>
                </Card>
              </CardDeck>
            </Col>
          </Row>
        </Fade>
      </Container>
    </>
  );
}
export default Servc;
